'use client'

import React, { ComponentType } from 'react'
import Ad, { AdBaseProps, adFormats } from './Ad'
const formats = adFormats.rectangle

type Type = keyof typeof formats

type RectangleAdProps = ComponentType<
  AdBaseProps & {
    type: Type
  }
>

export const RectangleAd: RectangleAdProps = ({ type, ...rest }) => {
  return <Ad formatId={formats[type]} {...rest} />
}
