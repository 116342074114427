'use client'

import React, { ComponentType } from 'react'
import Ad, { AdBaseProps, adFormats } from './Ad'

type HalfPageAdProps = ComponentType<AdBaseProps>

export const HalfPageAd: HalfPageAdProps = ({ ...rest }) => {
  return <Ad formatId={adFormats.halfPage.default} {...rest} />
}
