'use client'

import FlocklerEmbed from '@flockler/react-flockler-embed'
import { ComponentType } from 'react'

interface Props {
  embedUuid: string
}

const FlocklerSocialWall: ComponentType<Props> = async ({
  embedUuid
}: Props) => {
  return (
    <FlocklerEmbed
      siteUuid="1744e62ed2b0d583059b8799660ea2bc"
      embedUuid={embedUuid}
    />
  )
}

export default FlocklerSocialWall
